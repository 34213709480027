import React from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

const CarouselContainer = styled.div`
  display: flex;
  transition: ${props => (props.sliding ? 'none' : 'transform 1s ease')};
  transform: ${props => {
    if (!props.sliding) return 'translateX(calc(-90% - 20px))';
    if (props.dir === PREV) return 'translateX(calc(2 * (-90% - 20px)))';
    return 'translateX(0%)';
  }};
`;

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 90%;
  margin-right: 20px;
  order: ${props => props.order};
`;

import arrow_left from '../../static/arrow_left.png';
import arrow_left2x from '../../static/arrow_left@2x.png';

import arrow_right from '../../static/arrow_right.png';
import arrow_right2x from '../../static/arrow_right@2x.png';

const SlideButton = styled.div`
  background: #7774ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  cursor: pointer;
  transition: background 200ms;

  &:hover {
    background: #5956e9;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SliderButtons = styled.div`
  display: flex;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationNumber = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 99.33%;
  letter-spacing: -0.025em;
  color: #7774ff;
`;

const PaginationNumberRight = styled(PaginationNumber)`
  opacity: 0.3;
`;

const PaginationDelimiter = styled.div`
  width: 40px;
  height: 1px;
  background: #7774ff;
  margin: 0 16px;
`;

const Gradient = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10%;
  background: linear-gradient(
    270deg,
    #fffeaa 0%,
    rgba(255, 254, 170, 0) 182.05%
  );
`;

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};
const initialState = { pos: 0, sliding: false, dir: NEXT };

const Carousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const numItems = React.Children.count(props.children);
  const slide = dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
        <Gradient />
      </Wrapper>
      <Controls>
        <SliderButtons>
          <SlideButton onClick={() => slide(PREV)} img={arrow_left}>
            <img
              srcSet={`${arrow_left} 1x, ${arrow_left2x} 2x`}
              alt="Кнопка промотки слайдера влево"
            />
          </SlideButton>
          <SlideButton onClick={() => slide(NEXT)} img={arrow_right}>
            <img
              srcSet={`${arrow_right} 1x, ${arrow_right2x} 2x`}
              alt="Кнопка промотки слайдера вправо"
            />
          </SlideButton>
        </SliderButtons>
        <Pagination>
          <PaginationNumber>0{state.pos + 1}</PaginationNumber>
          <PaginationDelimiter />
          <PaginationNumberRight>0{numItems}</PaginationNumberRight>
        </Pagination>
      </Controls>
    </div>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case 'reset':
      return initialState;
    case PREV:
      if (window.amplitude != null) {
        window.amplitude.getInstance().logEvent('slide_carousel', {
          dir: 'prev',
          pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
        });
      }
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
      };
    case NEXT:
      if (window.amplitude != null) {
        window.amplitude.getInstance().logEvent('slide_carousel', {
          dir: 'next',
          pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
        });
      }
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
      };
    case 'stopSliding':
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default Carousel;
