import './global.css';

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { graphql } from 'gatsby';

// import { disableScroll, enableScroll } from '../utils/scroll';
import CTALinkClick from '../utils/CTALinkClick';

import Footer from '../components/Footer';
import Carousel from '../components/Carousel';
import SeoHelmet from '../components/SeoHelmet';

const Section = styled.div`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const LandingContiner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
`;

//###################################################//
// Burger Menu & Overlay                             //
//###################################################//

import burger from '../../static/burger.png';
import burger2x from '../../static/burger@2x.png';

const BurgerMenuButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: fixed;
  width: 88px;
  height: 88px;
  right: 32px;
  top: 24px;
  background: linear-gradient(
    180deg,
    rgba(250, 184, 196, 0.8) 0%,
    rgba(255, 237, 240, 0.8) 100%
  );
  backdrop-filter: blur(4px);
  border-radius: 50%;

  @media (max-width: 1365px) {
    width: 77px;
    height: 77px;
  }

  @media (max-width: 639px) {
    display: none;
  }
`;

const BurgerOverlay = styled.div``;
const BurgerOverlayFader = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: ${props => (props.opened ? '0' : '100%')};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  transition: left 0.4s ease-in 0.2s;
`;
const BurgerOverlayMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 418px;
  top: 0px;
  bottom: 0px;
  right: ${props => (props.opened ? '0px' : '-418px')};
  padding: 0 0 80px 80px;
  background: linear-gradient(
    180deg,
    rgba(250, 184, 196, 0.8) 0%,
    rgba(255, 237, 240, 0.8) 100%
  );
  backdrop-filter: blur(20px);
  transition: right 0.4s ease-in 0.2s;

  @media (max-width: 639px) {
    background: white;
    width: 100%;
    padding: 0 40px 40px;
    align-items: center;
    right: ${props => (props.opened ? '0px' : '-100%')};
  }
`;
const BurgerOverlayMenuList = styled.div`
  margin-bottom: 20px;

  @media (max-width: 639px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;
const BurgerOverlayMenuItem = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1d1e5f;
  margin-bottom: 40px;
  cursor: pointer;
  text-decoration: none;
  transition: color 200ms;

  :visited {
    text-decoration: none;
  }

  :hover {
    color: #5451c9;
  }

  @media (max-width: 639px) {
    margin-bottom: 20px;
  }
`;

import close_button from '../../static/close_button.png';
import close_button2x from '../../static/close_button@2x.png';

const BurgerOverlayMenuCloseButton = styled.div`
  position: absolute;
  top: 56px;
  right: 64px;
  cursor: pointer;

  @media (max-width: 639px) {
    display: none;
  }
`;

const MMainMenu = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 24px;
  z-index: 1;

  @media (max-width: 639px) {
    display: flex;
    box-shadow: ${props =>
      props.opened ? 'none' : '0px 0px 10px rgba(0, 0, 0, 0.15)'};
  }
`;

const MLogo = styled.div`
  margin-right: 8px;
  width: 51px;

  > img {
    width: 100%;
    display: block;
  }
`;

const MSiteName = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 97.83%;
  letter-spacing: -0.03em;
  color: #1d1e5f;
`;

import m_burger_icon from '../../static/m_burger_icon.png';
import m_burger_icon2x from '../../static/m_burger_icon@2x.png';

const MBurgerButton = styled.div`
  cursor: pointer;
  height: 40px;
  display: ${props => (props.opened ? 'none' : 'block')};
`;

import m_close_button from '../../static/m_close_button.png';
import m_close_button2x from '../../static/m_close_button@2x.png';

const MCloseButton = styled.div`
  cursor: pointer;
  display: ${props => (props.opened ? 'block' : 'none')};
  margin-right: 8px;
`;

import laptop from '../../static/laptop.png';
import laptop2x from '../../static/laptop@2x.png';

const MBurgerOverlayArt = styled.div`
  display: none;
  margin-top: 20px;
  @media (max-width: 639px) {
    display: block;
  }
`;

//###################################################//
// Hero Section                                      //
//###################################################//

const HeroSection = styled(Section)`
  background: #ffffff;
  height: 1024px;

  @media (max-width: 1365px) {
    height: 880px;
  }

  @media (max-width: 1200px) {
    height: 800px;
  }

  @media (max-width: 1023px) {
    height: auto;
    padding: 0 40px;
  }
`;

const HeroBackground = styled(Background)`
  overflow: hidden;

  @media (max-width: 1023px) {
    overflow: inherit;
  }
`;

const HeroBackgroundPlanetContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;

  @media (max-width: 1365px) {
    top: 20%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const HeroBackgroundPlanet = styled.div`
  position: absolute;
  left: -610px;
  top: -610px;
  width: 1220px;
  height: 1220px;
  background: #7774ff;
  border-radius: 50%;

  @media (max-width: 1365px) {
    width: 768px;
    height: 768px;
    left: -384px;
    top: -384px;
  }
`;

const HeroCornerPlanetContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const HeroCornerPlanet = styled.div`
  position: absolute;
  left: -200px;
  top: -200px;
  width: 400px;
  height: 400px;
  background: #ffe483;
  border-radius: 50%;

  @media (max-width: 1023px) {
    width: 172px;
    height: 172px;
    left: -12px;
    top: -68px;
  }
`;

const HeroGreenPlanet = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  left: 584px;
  top: 161px;
  background: #c0ff97;
  mix-blend-mode: normal;
  border-radius: 50%;

  @media (max-width: 1023px) {
    left: auto;
    right: 63px;
  }
`;

const HeroYellowPlanet = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  left: 500px;
  top: 716px;
  background: #ffe483;
  mix-blend-mode: normal;
  border-radius: 50%;

  @media (max-width: 1023px) {
    display: none;
  }
`;

import hero_art from '../../static/hero_art.png';
import hero_art2x from '../../static/hero_art@2x.png';

const HeroArt = styled.div`
  position: absolute;
  right: 7%;
  top: 90px;
  width: 46%;

  > img {
    width: 100%;
    display: block;
  }

  @media (max-width: 1200px) {
    right: 4%;
    top: 180px;
    width: 40%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

import m_hero_art from '../../static/m_hero_art.png';
import m_hero_art2x from '../../static/m_hero_art@2x.png';

const MHeroArtContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0px;
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;

const MHeroArt = styled.div`
  position: absolute;
  left: -160px;
  bottom: 0px;
`;

import logo from '../../static/logo.png';
import logo2x from '../../static/logo@2x.png';

const HeroLogo = styled.div`
  margin-right: 16px;

  @media (max-width: 1023px) {
    width: 51px;

    > img {
      width: 100%;
      display: block;
    }
  }
`;

const HeroWebSiteName = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 24px;
  left: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 97.83%;
  letter-spacing: -0.03em;
  color: #1d1e5f;

  @media (max-width: 1023px) {
    font-size: 25px;
  }

  @media (max-width: 639px) {
    display: none;
  }
`;

const HeroContent = styled.div`
  position: absolute;
  left: 160px;
  top: 200px;
  width: 672px;

  @media (max-width: 1365px) {
    left: 88px;
    top: 176px;
  }

  @media (max-width: 1023px) {
    position: relative;
    width: 100%;
    max-width: 672px;
    margin: 120px auto 316px;
    top: auto;
    left: auto;
  }
`;

const HeroTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 72px;
  letter-spacing: -0.02em;
  color: #292930;

  @media (max-width: 1023px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

const HeroSubTitle = styled.h3`
  width: 446px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #999fae;
  margin: 32px 0px;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    max-width: 446px;
    width: auto;
    margin: 16px 0 32px;
  }
`;

const PurpleText = styled.span`
  color: #7774ff;
  font-weight: bold;
`;

const HeroSectionCTAButton = styled.a`
  padding: 29px 24px;
  position: relative;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #7774ff;
  border-radius: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  width: 160px;
  transition: background 200ms;

  :hover {
    text-decoration: none;
    background: #5451c9;
  }
`;

const TryAlphaCTAButton = styled(HeroSectionCTAButton)`
  width: auto;
  height: auto;
  margin-top: 32px;
  padding: 18px 24px;
  max-width: 320px;

  @media (max-width: 639px) {
    padding: 12px 18px;
  }
`;

//###################################################//
// About IT-simulator Section                        //
//###################################################//

const AboutSimulatorSection = styled(Section)`
  background: #191852;
  padding: 0 40px;
`;

import about_simulator_cactus_art from '../../static/about_simulator_cactus_art.png';
import about_simulator_cactus_art2x from '../../static/about_simulator_cactus_art@2x.png';

const CactusArt = styled.div`
  position: absolute;
  right: 293px;
  bottom: 75px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

import about_simulator_clouds_art from '../../static/about_simulator_clouds_art.png';
import about_simulator_clouds_art2x from '../../static/about_simulator_clouds_art@2x.png';

const CloudsArt = styled.div`
  position: absolute;
  right: 152px;
  top: 258px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

import about_simulator_cube_art from '../../static/about_simulator_cube_art.png';
import about_simulator_cube_art2x from '../../static/about_simulator_cube_art@2x.png';

const CubeArt = styled.div`
  position: absolute;
  left: 218px;
  top: 145px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const AboutPurplePlanet = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  left: 66px;
  top: 524px;
  background: #9e59ff;
  mix-blend-mode: normal;
  opacity: 0.5;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const AboutBluePlanet = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 494px;
  height: 494px;
  left: 114px;
  top: 30px;
  background: #191852;
  mix-blend-mode: screen;

  @media (max-width: 1365px) {
    left: -158px;
    top: 52px;
  }
`;

const AboutPinkPlanetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const AboutPinkPlanet = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 146px;
  height: 146px;
  left: -73px;
  top: -73px;
  background: #fab8c4;
  mix-blend-mode: normal;
`;

const AboutGreenPlanet = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 235px;
  height: 235px;
  right: 255px;
  top: 126px;
  background: #6de4a2;
  mix-blend-mode: normal;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const AboutContent = styled.div`
  position: relative;
  max-width: 740px;
  margin: 146px auto 294px;
  padding-left: 125px;
  color: #ffffff;

  @media (max-width: 1365px) {
    padding-left: 0px;
    margin: 92px auto 276px;
  }
`;

const AboutContentTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 64px;

  @media (max-width: 1365px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

const AboutContentText = styled.div`
  padding-right: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.5;

  @media (max-width: 1365px) {
    padding-right: 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
`;

//###################################################//
// Goals Section                                     //
//###################################################//

const GoalsSection = styled(Section)`
  background: #ffffff;
  padding-bottom: 112px;

  @media (max-width: 1365px) {
    padding: 0;
  }
`;

import goals_senior_developer_art from '../../static/goals_senior_developer_art.png';
import goals_senior_developer_art2x from '../../static/goals_senior_developer_art@2x.png';

const SeniorArt = styled.div`
  position: absolute;
  left: 7px;
  top: 14px;

  @media (max-width: 1365px) {
    left: 8px;
    top: -6px;

    > img {
      width: 54px;
    }
  }
`;

import goals_enterprener_art from '../../static/goals_enterprener_art.png';
import goals_enterprener_art2x from '../../static/goals_enterprener_art@2x.png';

const EnterprenerArt = styled.div`
  position: absolute;
  left: 4px;
  top: 11px;

  @media (max-width: 1365px) {
    left: 3px;
    top: 5px;

    > img {
      width: 75px;
    }
  }
`;

import goals_it_interview_art from '../../static/goals_it_interview_art.png';
import goals_it_interview_art2x from '../../static/goals_it_interview_art@2x.png';

const ITInterviewArt = styled.div`
  position: absolute;
  left: 23px;
  top: -16px;

  @media (max-width: 1365px) {
    left: 24px;
    top: -8px;

    > img {
      width: 40px;
    }
  }
`;

import goals_rocket_plans_art from '../../static/goals_rocket_plans_art.png';
import goals_rocket_plans_art2x from '../../static/goals_rocket_plans_art@2x.png';

const GoalsRocketArt = styled.div`
  position: absolute;
  left: 264px;
  top: -250px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const MGoalsRocketArtContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 0px;
  display: none;

  @media (max-width: 1365px) {
    display: block;
  }
`;

const MGoalsRocketArt = styled.div`
  position: absolute;
  left: -135px;
  bottom: -22px;
  width: 270px;

  > img {
    display: block;
    width: 100%;
  }
`;

import goals_exploring_art from '../../static/goals_exploring_art.png';
import goals_exploring_art2x from '../../static/goals_exploring_art@2x.png';

const GoalsExploringArt = styled.div`
  position: absolute;
  top: 563px;
  left: 48px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

import m_goals_exploring_art from '../../static/m_goals_exploring_art.png';
import m_goals_exploring_art2x from '../../static/m_goals_exploring_art@2x.png';

const MGoalsExploringArt = styled.div`
  position: absolute;
  top: 56px;
  right: 15px;
  display: none;

  @media (max-width: 1365px) {
    display: block;
  }
`;

import goals_kubik_rubika_art from '../../static/goals_kubik_rubika_art.png';
import goals_kubik_rubika_art2x from '../../static/goals_kubik_rubika_art@2x.png';

const GoalsKubikRubikaArtContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;

  @media (max-width: 1365px) {
    right: 67px;
    top: auto;
    bottom: 0;
  }
`;
const GoalsKubikRubikaArt = styled.div`
  position: absolute;
  left: -52px;
  top: -50px;

  @media (max-width: 1365px) {
    width: 60px;
    bottom: -25px;
    left: -30px;
    top: auto;

    > img {
      display: block;
      width: 100%;
    }
  }
`;

import goals_diamond_art from '../../static/goals_diamond_art.png';
import goals_diamond_art2x from '../../static/goals_diamond_art@2x.png';

const GoalsDiamondArtContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: 1365px) {
    right: 67px;
    bottom: 0;
  }
`;

const GoalsDiamondArt = styled.div`
  position: absolute;
  left: -65px;
  bottom: -48px;

  @media (max-width: 1365px) {
    width: 70px;
    bottom: -18px;
    left: -35px;

    > img {
      display: block;
      width: 100%;
    }
  }
`;

const GoalsContent = styled.div`
  position: relative;

  @media (max-width: 1365px) {
    max-width: 640px;
    margin: 0 auto;
  }
`;

const GoalsTitle = styled.h2`
  position: absolute;
  width: 384px;
  left: 161px;
  top: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -0.02em;
  color: #292930;

  @media (max-width: 1365px) {
    position: relative;
    left: auto;
    top: auto;
    max-width: 260px;
    width: auto;
    font-size: 35px;
    line-height: 40px;
    padding: 80px 40px 0px;
  }
`;

const GoalsList = styled.div`
  max-width: 640px;
  margin: 160px 160px 112px auto;

  @media (max-width: 1365px) {
    margin: 72px 0 56px 0;
  }
`;

const PersonalGoal = styled.div`
  margin-bottom: 48px;
  border-radius: 16px;
  padding: 16px;
  position: relative;

  @media (max-width: 1365px) {
    border-radius: 0px;
    margin-bottom: 40px;
  }
`;

const SeniorGoal = styled(PersonalGoal)`
  background: rgba(135, 212, 255, 0.5);
`;

const EnterprenerGoal = styled(PersonalGoal)`
  background: rgba(255, 228, 131, 0.5);
`;

const ITInterviewGoal = styled(PersonalGoal)`
  background: rgba(119, 116, 255, 0.5);
`;

const GoalWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 32px 60px 28px 24px;
`;

const GoalWrapperInner = styled.div`
  display: flex;

  @media (max-width: 1365px) {
    margin-bottom: 24px;
    align-items: center;
  }
`;

const GoalContent = styled.div`
  margin-left: 24px;
`;

const GoalCircleArt = styled.div`
  min-width: 104px;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  position: relative;

  @media (max-width: 1365px) {
    min-width: 80px;
    width: 80px;
    height: 80px;
  }
`;

const SeniorGoalCircleArt = styled(GoalCircleArt)`
  background: #87d4ff;
`;
const EnterprenerGoalCircleArt = styled(GoalCircleArt)`
  background: #ffba68;
`;
const ITInterviewGoalCircleArt = styled(GoalCircleArt)`
  background: #7774ff;
`;

const GoalTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #292930;
  margin-bottom: 16px;

  @media (max-width: 1365px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const GoalDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: #999fae;
  margin-bottom: 16px;

  @media (max-width: 1365px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

const GoalDescriptionContainer = styled.div`
  display: block;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const MGoalDescriptionContainer = styled.div`
  display: none;

  @media (max-width: 1365px) {
    display: block;
  }
`;

import arrow from '../../static/arrow.png';
import arrow2x from '../../static/arrow@2x.png';

const GoalLink = styled.a`
  display: inline-block;
  cursor: pointer;
  align-items: center;
  text-decoration: none;

  :hover {
    text-decoration: none;
  }

  @media (max-width: 1365px) {
    font-size: 15px;
    line-height: 16px;
  }
`;

const GoalLinkText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: -0.045em;
  color: #27272e;
  margin-right: 16px;
  transition: color 200ms;

  &:hover {
    color: #5451c9;
  }
`;
//###################################################//
// "What you get" Section                            //
//###################################################//

import what_you_get_art from '../../static/what_you_get_art.png';
import what_you_get_art2x from '../../static/what_you_get_art@2x.png';

const WhatYouGetArt = styled.div`
  position: absolute;
  top: -69px;
  left: 144px;

  @media (max-width: 1365px) {
    width: 209px;
    left: 26px;
    top: -45px;

    > img {
      display: block;
      width: 100%;
    }
  }
`;

const WhatYouGetSection = styled(Section)`
  background: #fffeaa;

  @media (max-width: 1365px) {
    padding: 0 40px;
  }
`;

const WhatYouGetContent = styled.div`
  position: relative;
  max-width: 1120px;
  margin: 96px auto 128px;

  @media (max-width: 1365px) {
    margin: 184px auto 40px;
    max-width: 1024px;
  }
`;
const WhatYouGetHeader = styled.div`
  max-width: 500px;
  margin: 0 40px 116px auto;

  @media (max-width: 1365px) {
    margin: 0 0 52px;
    max-width: none;
  }
`;
const WhatYouGetTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 64px;
  color: #292930;
  margin-bottom: 24px;

  @media (max-width: 1365px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
const WhatYouGetDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #292930;

  @media (max-width: 1365px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
`;
const WhatYouGetList = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1365px) {
    display: none;
  }
`;
const WhatYouGetCarousel = styled.div`
  display: none;

  @media (max-width: 1365px) {
    display: block;
  }
`;

const WhatYouGetItem = styled.div`
  padding: 32px 0;
  width: calc(33.33% - 32px);
  margin-right: 32px;
  :nth-child(odd) {
    border-top: 2px solid #7774ff;
  }

  :nth-child(even) {
    border-top: 2px solid #62a3ff;
  }

  @media (max-width: 1365px) {
    padding: 32px 0 32px 0;
    width: auto;
    margin-right: 0px;
  }
`;

const WhatYouGetItemTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #292930;
  margin-bottom: 16px;
`;
const WhatYouGetItemDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #292930;
  opacity: 0.7;
`;

//###################################################//
// "How it works" Section                            //
//###################################################//

const HowItWorksSection = styled(Section)`
  background: #ffffff;

  @media (max-width: 1365px) {
    padding: 0 40px;
  }
`;

import how_it_works_art from '../../static/how_it_works_art.png';
import how_it_works_art2x from '../../static/how_it_works_art@2x.png';

const HowItWorksArt = styled.div`
  position: absolute;
  right: 107px;
  top: -152px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const HowItWorksContent = styled.div`
  position: relative;
  margin: 100px 0 0;

  @media (max-width: 1365px) {
    margin: 56px auto 40px;
    max-width: 1024px;
  }
`;
const HowItWorksHeader = styled.div`
  margin: 0 0 96px 160px;
  max-width: 560px;

  @media (max-width: 1365px) {
    max-width: none;
    margin: 0 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const HowItWorksTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 64px;
  margin-bottom: 24px;

  @media (max-width: 1365px) {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
`;
const HowItWorksDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #999fae;

  @media (max-width: 1365px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;
const HowItWorksList = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1365px) {
    flex-direction: column;
  }
`;
const HowItWorksItem = styled.div`
  width: 50%;
  padding: 64px 100px 64px 64px;
  border-top: 1px solid #e3e6e9;
  border-right: 1px solid #e3e6e9;
  display: flex;

  @media (max-width: 1365px) {
    width: auto;
    border-right: none;
    flex-direction: column;
    align-items: center;
    padding: 33px 0 32px;
  }
`;

import how_it_works_fun_art from '../../static/how_it_works_fun_art.png';
import how_it_works_fun_art2x from '../../static/how_it_works_fun_art@2x.png';

import how_it_works_life_art from '../../static/how_it_works_life_art.png';
import how_it_works_life_art2x from '../../static/how_it_works_life_art@2x.png';

import how_it_works_multilayers_art from '../../static/how_it_works_multilayers_art.png';
import how_it_works_multilayers_art2x from '../../static/how_it_works_multilayers_art@2x.png';

import how_it_works_practice_art from '../../static/how_it_works_practice_art.png';
import how_it_works_practice_art2x from '../../static/how_it_works_practice_art@2x.png';

import m_how_it_works_fun_art from '../../static/m_how_it_works_fun_art.png';
import m_how_it_works_fun_art2x from '../../static/m_how_it_works_fun_art@2x.png';

import m_how_it_works_life_art from '../../static/m_how_it_works_life_art.png';
import m_how_it_works_life_art2x from '../../static/m_how_it_works_life_art@2x.png';

import m_how_it_works_multilayers_art from '../../static/m_how_it_works_multilayers_art.png';
import m_how_it_works_multilayers_art2x from '../../static/m_how_it_works_multilayers_art@2x.png';

import m_how_it_works_practice_art from '../../static/m_how_it_works_practice_art.png';
import m_how_it_works_practice_art2x from '../../static/m_how_it_works_practice_art@2x.png';

const HowItWorksItemArt = styled.div`
  min-width: 210px;

  @media (max-width: 1365px) {
    display: none;
  }
`;

const MHowItWorksItemArt = styled.div`
  display: none;
  margin-bottom: 24px;

  @media (max-width: 1365px) {
    display: block;
  }
`;

const HowItWorksItemContent = styled.div`
  margin-left: 36px;

  @media (max-width: 1365px) {
    text-align: center;
    margin: 0px;
  }
`;
const HowItWorksItemTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #292930;
  margin-bottom: 16px;
`;
const HowItWorksItemDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292930;
  opacity: 0.7;
`;

//###################################################//
// About Authros Section                             //
//###################################################//

import about_authors_art from '../../static/about_authors_art.png';
import about_authors_art2x from '../../static/about_authors_art@2x.png';

import german_gurov_photo from '../../static/german_gurov_photo.png';
import german_gurov_photo2x from '../../static/german_gurov_photo@2x.png';

import max_koshel_photo from '../../static/max_koshel_photo.png';
import max_koshel_photo2x from '../../static/max_koshel_photo@2x.png';

const RockerAndPlanetArt = styled.div`
  position: absolute;
  top: 82px;
  right: 116px;

  @media (max-width: 1365px) {
    width: 164px;
    top: 24px;
    right: 10px;

    > img {
      display: block;
      width: 100%;
    }
  }
`;
const AboutAuthorsSection = styled(Section)`
  background: #ecf2f6;
  padding: 0 40px;
`;

const AboutAuthorsContent = styled.div`
  margin: 100px 0 0;
  position: relative;

  @media (max-width: 1365px) {
    margin: 40px auto 56px;
    max-width: 1024px;
  }
`;
const AboutAuthorsHeader = styled.div`
  margin: 0 0 96px 160px;
  max-width: 540px;

  @media (max-width: 1365px) {
    margin: 0;
    max-width: none;
  }
`;
const AboutAuthorsTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 64px;
  margin-bottom: 24px;

  @media (max-width: 1365px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 33px;
  }

  @media (max-width: 1365px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 33px;
    padding-right: 170px;
  }

  @media (max-width: 500px) {
    max-width: 150px;
  }
`;

const AboutAuthorsDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #999fae;

  @media (max-width: 1365px) {
    font-size: 16px;
    line-height: 24px;
    padding-right: 170px;
  }

  @media (max-width: 500px) {
    padding-right: 0;
  }
`;
const AboutAuthorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 138px auto 104px;
  max-width: 1120px;

  @media (max-width: 1365px) {
    flex-direction: column;
    margin: 22px auto 56px;
  }
`;
const AboutAuthorsItem = styled.div`
  margin-right: 128px;
  width: calc(50% - 128px);

  @media (max-width: 1365px) {
    margin: 0;
    width: auto;
  }
`;
const AboutAuthorsItemWrapper = styled.div`
  border-radius: 16px;
  padding: 40px;

  @media (max-width: 1365px) {
    padding: 24px;
  }
`;
const MaxItem = styled(AboutAuthorsItemWrapper)`
  background: #ffecaa;
`;
const GermanItem = styled(AboutAuthorsItemWrapper)`
  background: #dbdeff;
  margin-top: 240px;

  @media (max-width: 1365px) {
    margin-top: 40px;
  }
`;
const AboutAuthorItemHeader = styled.div`
  display: flex;
`;
const AboutAuthorItemPhoto = styled.div`
  margin-right: 24px;
`;
const AboutAuthorItemHeaderContent = styled.div``;
const AboutAuthorItemTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
  color: #292930;
  margin-bottom: 8px;

  @media (max-width: 1365px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
const AboutAuthorItemLink = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #7774ff;
  text-decoration: none;
  transition: color 200ms;

  :visited {
    color: #7774ff;
  }

  :hover {
    text-decoration: none;
    color: #5451c9;
  }

  @media (max-width: 1365px) {
    font-size: 15px;
    line-height: 24px;
  }
`;
const AboutAuthorDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: #292930;

  @media (max-width: 1365px) {
    font-size: 15px;
    line-height: 24px;
  }
`;
const PinkPlanet2 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 146px;
  height: 146px;
  left: 410px;
  top: 460px;
  background: #fab8c4;
  mix-blend-mode: normal;

  @media (max-width: 1365px) {
    left: -80px;
    top: 610px;
  }
`;
const SkyBluePlanet2 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 268px;
  height: 268px;
  left: -164px;
  top: 882px;
  background: #9af3ff;
  mix-blend-mode: normal;

  @media (max-width: 1365px) {
    width: 56px;
    height: 56px;
    left: -23px;
    top: auto;
    bottom: 240px;
  }
`;
const SkyPurplePlanet2 = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  right: 100px;
  top: 1099px;
  background: #9e59ff;
  mix-blend-mode: normal;
  opacity: 0.5;

  @media (max-width: 1365px) {
    right: 10px;
    top: 800px;
  }
`;

//###################################################//
// Alpha Telegram Section                            //
//###################################################//

import alpha_telegram_art from '../../static/alpha_telegram_art.png';
import alpha_telegram_art2x from '../../static/alpha_telegram_art@2x.png';

import telegram_logo from '../../static/telegram_logo.png';
import telegram_logo2x from '../../static/telegram_logo@2x.png';

import m_alpha_telegram_art from '../../static/m_alpha_telegram_art.png';
import m_alpha_telegram_art2x from '../../static/m_alpha_telegram_art@2x.png';

const AlphaTelegramArtContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 1272px) {
    overflow: hidden;
    width: 100%;
  }
`;

const AlphaTelegramArt = styled.div`
  display: block;
  @media (max-width: 375px) {
    display: none;
  }
`;

const MAlphaTelegramArt = styled.div`
  display: none;
  @media (max-width: 375px) {
    display: block;
  }
`;

const AlphaTelegramSection = styled(Section)`
  background: #ffffff;
  padding-bottom: 75px;

  @media (max-width: 375px) {
    padding-bottom: 16px;
  }
`;

const AlphaTelegramContent = styled.div`
  position: relative;
  max-width: 1024px;
  margin: 88px auto;
  @media (max-width: 375px) {
    padding: 0 40px;
    position: absolute;
    top: 50px;
    z-index: 1;
    width: 100%;
  }
`;

const AlphaTelegramTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 64px;
  text-align: center;
  color: #1d1e5f;
  margin-bottom: 8px;

  @media (max-width: 375px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

const AlphaTelegramCTAButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    top: 67%;
  }
`;
const AlphaTelegramCTAButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px 14px 20px rgba(35, 34, 87, 0.4),
    0px 48px 48px rgba(0, 0, 0, 0.12);
  border-radius: 80px;
  margin-top: -72px;
  text-decoration: none;
  cursor: pointer;
  color: #1d1e5f;
  transition: color 200ms;

  :hover {
    text-decoration: none;
    color: #5451c9;
  }
`;

const AlphaTelegramCTAButtonLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 80%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 16px;
`;

export default function LandingPage({ data, location }) {
  const siteName = _.get(data, 'site.siteMetadata.siteName');
  const telegramBot = _.get(data, 'site.siteMetadata.socialLinks.telegramBot');
  const maxLinkedin = _.get(data, 'site.siteMetadata.socialLinks.maxLinkedin');
  const germanLinkedin = _.get(
    data,
    'site.siteMetadata.socialLinks.germanLinkedin',
  );
  const [shouldShowBurgerOverlay, setShouldShowBurgerOverlay] = React.useState(
    false,
  );
  const onOpenBurgerOverlay = React.useCallback(() => {
    // disableScroll();
    setShouldShowBurgerOverlay(true);
  }, []);
  const onCloseBurgerOverlay = React.useCallback(() => {
    // enableScroll();
    setShouldShowBurgerOverlay(false);
  }, []);
  const onMenuItemClick = React.useCallback(
    item => () => {
      if (window.amplitude != null) {
        window.amplitude
          .getInstance()
          .logEvent('click_overlay_menu_item', { item });
      }
      onCloseBurgerOverlay();
    },
    [],
  );

  const whatYouGetItems = [
    <WhatYouGetItem key="0">
      <WhatYouGetItemTitle>
        Опыт разработки веб&#8209;сервисов
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Запустите и&nbsp;масштабируете 6&nbsp;проектов в&nbsp;4&nbsp;прикладных
        областях: финтех, онлайн магазины, коммуникация и&nbsp;развлечения.
        Построите свои Shopify, Tinkoff, Telegram, Vkontakte.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="1">
      <WhatYouGetItemTitle>
        Навыки проектирования веб&#8209;сервисов
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Научитесь перебирать возможные решения и&nbsp;анализировать недостатки
        каждого из&nbsp;них. Разберетесь, когда использовать кэш, базы данных,
        очереди и&nbsp;балансировщики нагрузки.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="2">
      <WhatYouGetItemTitle>
        Доступ ко&nbsp;всем пройденным материалам
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Пройденная теория и&nbsp;практика остаются открытыми после окончания
        обучения. Если в&nbsp;будущем вы&nbsp;столкнетесь с&nbsp;похожими
        задачами&nbsp;&mdash; возвращайтесь в&nbsp;любой момент, чтобы освежить
        знания.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="3">
      <WhatYouGetItemTitle>
        Методика анализа требований к&nbsp;продукту
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Примените на&nbsp;практике методику job stories. Эта методика поможет
        прочувствовать будущее состояние продукта, увидеть пользователя
        за&nbsp;бездушной системой.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="4">
      <WhatYouGetItemTitle>Навыки рисования диаграмм</WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Прокачаете навыки работы с&nbsp;диаграммами и&nbsp;нарисуете
        60&nbsp;схем в&nbsp;draw.oi и&nbsp;plantuml. Разберетесь, когда рисовать
        диаграммы компонентов, а&nbsp;когда&nbsp;&mdash; последовательностей.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="5">
      <WhatYouGetItemTitle>
        Навыки работы с&nbsp;облачными платформами
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Поднимите Kubernetes кластер и&nbsp;научитесь автоматизировать процесс
        сборки и&nbsp;развертывания ваших веб&#8209;сервисов.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="6">
      <WhatYouGetItemTitle>
        Детальный план, как трансформировать идею в&nbsp;готовое решение
      </WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Программа затрагивает все стадии работы над продуктом: сбор
        и&nbsp;анализ требований, проектирование, разработка, масштабирование,
        эволюция. Вам не&nbsp;нужно собирать знания по&nbsp;крупицам
        и&nbsp;выстраивать план обучения самостоятельно. Мы&nbsp;уже сделали это
        за&nbsp;вас.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="7">
      <WhatYouGetItemTitle>Цифровой сертификат</WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Каждый ученик по&nbsp;окончанию обучения получит цифровой сертификат,
        который содержит количество набранных очков и&nbsp;будет хорошим
        дополнением к&nbsp;резюме.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
    <WhatYouGetItem key="8">
      <WhatYouGetItemTitle>Рекомендация от&nbsp;авторов</WhatYouGetItemTitle>
      <WhatYouGetItemDescription>
        Лучшие ученики получат рекомендацию авторов при трудоустройстве.
      </WhatYouGetItemDescription>
    </WhatYouGetItem>,
  ];

  const seniorDescription = (
    <>
      <GoalDescription>
        Чтобы преуспеть на&nbsp;позиции разработчика, вы&nbsp;должны развивать
        междисциплинарные навыки в&nbsp;области анализа и&nbsp;управления
        продуктом. Перестать быть инструментом в&nbsp;руках бизнеса,
        а&nbsp;стать его движущей силой.
        {siteName} поможет построить мост между бизнесом и&nbsp;разработкой,
        научит принимать продуктовые решения.
      </GoalDescription>
      <GoalLink
        href={telegramBot}
        target="_blank"
        rel="noopener noreferrer"
        onClick={CTALinkClick('senior_learn_more_link')}
      >
        <GoalLinkText>Узнать больше</GoalLinkText>{' '}
        <img
          style={{ width: '6px', height: '12px' }}
          srcSet={`${arrow} 1x, ${arrow2x} 2x`}
        />
      </GoalLink>
    </>
  );

  const enterprenerDescription = (
    <>
      <GoalDescription>
        Чтобы выжить в&nbsp;конкурентной среде, стартапы должны научиться
        срезать углы. Сокращать путь от&nbsp;идеи до&nbsp;реального пользователя
        и&nbsp;быть готовыми поменять продукт, если того требует бизнес.{' '}
        {siteName} поможет внедрить культуру принятия архитектурных решений,
        научит строить гибкие и&nbsp;прагматичные системы.
      </GoalDescription>
      <GoalLink
        href={telegramBot}
        target="_blank"
        rel="noopener noreferrer"
        onClick={CTALinkClick('enterprener_learn_more_link')}
      >
        <GoalLinkText>Узнать больше</GoalLinkText>{' '}
        <img
          style={{ width: '6px', height: '12px' }}
          srcSet={`${arrow} 1x, ${arrow2x} 2x`}
        />
      </GoalLink>
    </>
  );
  const ITInterviewDescription = (
    <>
      <GoalDescription>
        Чтобы устроиться в&nbsp;достойную&nbsp;IT компанию, вы&nbsp;должны
        продемонстрировать навыки в&nbsp;области проектирования систем. Говорить
        с&nbsp;интервьювером на&nbsp;одном языке и&nbsp;понимать, что
        от&nbsp;вас хотят.
        {siteName} поможет систематизировать знания по&nbsp;проектированию
        систем, научит анализировать требования к&nbsp;продукту
        и&nbsp;перебирать возможные решения.
      </GoalDescription>
      <GoalLink
        href={telegramBot}
        target="_blank"
        rel="noopener noreferrer"
        onClick={CTALinkClick('it_interview_learn_more_link')}
      >
        <GoalLinkText>Узнать больше</GoalLinkText>{' '}
        <img
          style={{ width: '6px', height: '12px' }}
          srcSet={`${arrow} 1x, ${arrow2x} 2x`}
        />
      </GoalLink>
    </>
  );

  return (
    <LandingContiner>
      <SeoHelmet location={location} />
      <HeroSection>
        <HeroBackground>
          <HeroBackgroundPlanetContainer>
            <HeroBackgroundPlanet />
          </HeroBackgroundPlanetContainer>
          <HeroCornerPlanetContainer>
            <HeroCornerPlanet />
          </HeroCornerPlanetContainer>
          <HeroGreenPlanet />
          <HeroYellowPlanet />
          <HeroArt>
            <img
              srcSet={`${hero_art} 1x, ${hero_art2x} 2x`}
              alt="Изображение лаптопа, летающей тарелки и планет"
            />
          </HeroArt>
          <MHeroArtContainer>
            <MHeroArt>
              <img
                srcSet={`${m_hero_art} 1x, ${m_hero_art2x} 2x`}
                alt="Изображение летающей тарелки и планет"
              />
            </MHeroArt>
          </MHeroArtContainer>
        </HeroBackground>
        <HeroWebSiteName>
          <HeroLogo>
            <img srcSet={`${logo} 1x, ${logo2x} 2x`} alt="Логотип нердии" />
          </HeroLogo>
          <span>{siteName}</span>
          <PurpleText>.</PurpleText>
        </HeroWebSiteName>
        <HeroContent>
          <HeroTitle>
            Научим проектировать и&nbsp;масштабировать веб&#8209;сервисы{' '}
            <PurpleText>_</PurpleText>
          </HeroTitle>
          <HeroSubTitle>
            Это необычный курс.
            <br />
            {siteName}&nbsp;&mdash; это симулятор работы в&nbsp;IT-компании 🤓
          </HeroSubTitle>
          <HeroSectionCTAButton
            href={telegramBot}
            target="_blank"
            rel="noopener noreferrer"
            onClick={CTALinkClick('hero_start_button')}
          >
            Начать
          </HeroSectionCTAButton>
        </HeroContent>
      </HeroSection>

      <AboutSimulatorSection>
        <Background>
          <AboutPurplePlanet />
          <AboutBluePlanet />
          <AboutPinkPlanetContainer>
            <AboutPinkPlanet />
          </AboutPinkPlanetContainer>
          <AboutGreenPlanet />
          <CubeArt>
            <img
              srcSet={`${about_simulator_cube_art} 1x, ${about_simulator_cube_art2x} 2x`}
              alt="Изображение абстрактного куба"
            />
          </CubeArt>
          <CloudsArt>
            <img
              srcSet={`${about_simulator_clouds_art} 1x, ${about_simulator_clouds_art2x} 2x`}
              alt="Изображение облаков"
            />
          </CloudsArt>
          <CactusArt>
            <img
              srcSet={`${about_simulator_cactus_art} 1x, ${about_simulator_cactus_art2x} 2x`}
              alt="Изображение кактуса"
            />
          </CactusArt>
        </Background>
        <AboutContent>
          <AboutContentTitle>
            Вы&nbsp;попадете в&nbsp;вымышленный мир {siteName},
          </AboutContentTitle>
          <AboutContentText>
            <p>
              где поможете двум бизнесменам построить межгалактическую
              корпорацию.
            </p>
            <p>
              Никакой скучной и&nbsp;сухой теории: только практический опыт
              разработки в&nbsp;безопасной среде. Научитесь перебирать возможные
              решения и&nbsp;анализировать недостатки каждого из&nbsp;них,
              а&nbsp;виртуальные персонажи подскажут, если что-то пошло
              не&nbsp;так.
            </p>
          </AboutContentText>
        </AboutContent>
      </AboutSimulatorSection>

      <GoalsSection id="for-whom">
        <Background>
          <GoalsRocketArt>
            <img
              srcSet={`${goals_rocket_plans_art} 1x, ${goals_rocket_plans_art2x} 2x`}
              alt="Изображение ракеты и других абстракций"
            />
          </GoalsRocketArt>
          <GoalsExploringArt>
            <img
              srcSet={`${goals_exploring_art} 1x, ${goals_exploring_art2x} 2x`}
              alt="Изображение телескопа, планеты, макета веб-сайта и других абстракций"
            />
          </GoalsExploringArt>
          <MGoalsRocketArtContainer>
            <MGoalsRocketArt>
              <img
                srcSet={`${goals_rocket_plans_art} 1x, ${goals_rocket_plans_art2x} 2x`}
                alt="Изображение ракеты и других абстракций"
              />
            </MGoalsRocketArt>
          </MGoalsRocketArtContainer>
        </Background>
        <GoalsContent>
          <MGoalsExploringArt>
            <img
              srcSet={`${m_goals_exploring_art} 1x, ${m_goals_exploring_art2x} 2x`}
              alt="Изображение телескопа и других абстракций"
            />
          </MGoalsExploringArt>
          <GoalsTitle>Кому и&nbsp;зачем это нужно</GoalsTitle>
          <GoalsList>
            <SeniorGoal>
              <GoalWrapper>
                <GoalWrapperInner>
                  <SeniorGoalCircleArt>
                    <SeniorArt>
                      <img
                        srcSet={`${goals_senior_developer_art} 1x, ${goals_senior_developer_art2x} 2x`}
                        alt="Изображение старшего разработчика"
                      />
                    </SeniorArt>
                  </SeniorGoalCircleArt>
                  <GoalContent>
                    <GoalTitle>Стать senior разработчиком</GoalTitle>
                    <GoalDescriptionContainer>
                      {seniorDescription}
                    </GoalDescriptionContainer>
                  </GoalContent>
                </GoalWrapperInner>
                <MGoalDescriptionContainer>
                  {seniorDescription}
                </MGoalDescriptionContainer>
              </GoalWrapper>
              <GoalsKubikRubikaArtContainer>
                <GoalsKubikRubikaArt>
                  <img
                    srcSet={`${goals_kubik_rubika_art} 1x, ${goals_kubik_rubika_art2x} 2x`}
                    alt="Изображение кубика Рубика"
                  />
                </GoalsKubikRubikaArt>
              </GoalsKubikRubikaArtContainer>
            </SeniorGoal>
            <EnterprenerGoal>
              <GoalWrapper>
                <GoalWrapperInner>
                  <EnterprenerGoalCircleArt>
                    <EnterprenerArt>
                      <img
                        srcSet={`${goals_enterprener_art} 1x, ${goals_enterprener_art2x} 2x`}
                        alt="Изображение стартапера"
                      />
                    </EnterprenerArt>
                  </EnterprenerGoalCircleArt>
                  <GoalContent>
                    <GoalTitle>
                      Трансформировать бизнес-идею в&nbsp;готовое решение
                    </GoalTitle>
                    <GoalDescriptionContainer>
                      {enterprenerDescription}
                    </GoalDescriptionContainer>
                  </GoalContent>
                </GoalWrapperInner>
                <MGoalDescriptionContainer>
                  {enterprenerDescription}
                </MGoalDescriptionContainer>
              </GoalWrapper>
            </EnterprenerGoal>
            <ITInterviewGoal>
              <GoalWrapper>
                <GoalWrapperInner>
                  <ITInterviewGoalCircleArt>
                    <ITInterviewArt>
                      <img
                        srcSet={`${goals_it_interview_art} 1x, ${goals_it_interview_art2x} 2x`}
                        alt="Изображение разработчика"
                      />
                    </ITInterviewArt>
                  </ITInterviewGoalCircleArt>
                  <GoalContent>
                    <GoalTitle>
                      Найти работу в&nbsp;топовых&nbsp;IT компаниях
                    </GoalTitle>
                    <GoalDescriptionContainer>
                      {ITInterviewDescription}
                    </GoalDescriptionContainer>
                  </GoalContent>
                </GoalWrapperInner>
                <MGoalDescriptionContainer>
                  {ITInterviewDescription}
                </MGoalDescriptionContainer>
              </GoalWrapper>
              <GoalsDiamondArtContainer>
                <GoalsDiamondArt>
                  <img
                    srcSet={`${goals_diamond_art} 1x, ${goals_diamond_art2x} 2x`}
                    alt="Изображение драгоценного камня"
                  />
                </GoalsDiamondArt>
              </GoalsDiamondArtContainer>
            </ITInterviewGoal>
          </GoalsList>
        </GoalsContent>
      </GoalsSection>

      <WhatYouGetSection id="skills-you-get">
        <Background>
          <WhatYouGetArt>
            <img
              srcSet={`${what_you_get_art} 1x, ${what_you_get_art2x} 2x`}
              alt="Изображение формы авторизации на веб-сайт, замок и другие абстракции"
            />
          </WhatYouGetArt>
        </Background>
        <WhatYouGetContent>
          <WhatYouGetHeader>
            <WhatYouGetTitle>Что вы&nbsp;получите</WhatYouGetTitle>
            <WhatYouGetDescription>
              Практический опыт и&nbsp;навыки разработки и&nbsp;проектирования
              веб&#8209;сервисов, закрепленные цифровым сертификатом
              и&nbsp;рекомендацией при трудоустройстве.
            </WhatYouGetDescription>
            <TryAlphaCTAButton
              href={telegramBot}
              target="_blank"
              rel="noopener noreferrer"
              onClick={CTALinkClick('what_you_get_button')}
            >
              Попробовать α&#8209;версию бесплатно
            </TryAlphaCTAButton>
          </WhatYouGetHeader>
          <WhatYouGetCarousel>
            <Carousel>{whatYouGetItems}</Carousel>
          </WhatYouGetCarousel>
          <WhatYouGetList>{whatYouGetItems}</WhatYouGetList>
        </WhatYouGetContent>
      </WhatYouGetSection>

      <HowItWorksSection id="how-it-works">
        <Background>
          <HowItWorksArt>
            <img
              srcSet={`${how_it_works_art} 1x, ${how_it_works_art2x} 2x`}
              alt="Изображение камеры GoPro, макета веб-сайта, телефона и диграммы"
            />
          </HowItWorksArt>
        </Background>
        <HowItWorksContent>
          <HowItWorksHeader>
            <HowItWorksTitle>Как устроен симулятор {siteName}</HowItWorksTitle>
            <HowItWorksDescription>
              <p>
                Весь мир симулятора вымышленный, но&nbsp;за&nbsp;каждым
                персонажем и&nbsp;диалогом стоят реальные люди и&nbsp;ситуации.
                Вы&nbsp;поставите себя на&nbsp;место тех людей, которые
                построили успешный&nbsp;IT бизнес.
              </p>
              <p>
                Пройдете их&nbsp;путь от&nbsp;становления идеи
                до&nbsp;её&nbsp;масштабирования на&nbsp;миллион пользователей.
              </p>
            </HowItWorksDescription>
            <TryAlphaCTAButton
              href={telegramBot}
              target="_blank"
              rel="noopener noreferrer"
              onClick={CTALinkClick('how_it_works_button')}
            >
              Попробовать α&#8209;версию бесплатно
            </TryAlphaCTAButton>
          </HowItWorksHeader>
          <HowItWorksList>
            <HowItWorksItem>
              <HowItWorksItemArt>
                <img
                  srcSet={`${how_it_works_practice_art} 1x, ${how_it_works_practice_art2x} 2x`}
                  alt="Изображение руки сложенной в знак мира, компьютер и редактор кода"
                />
              </HowItWorksItemArt>
              <MHowItWorksItemArt>
                <img
                  srcSet={`${m_how_it_works_practice_art} 1x, ${m_how_it_works_practice_art2x} 2x`}
                  alt="Изображение руки сложенной в знак мира, компьютер и редактор кода"
                />
              </MHowItWorksItemArt>
              <HowItWorksItemContent>
                <HowItWorksItemTitle>
                  Практика на&nbsp;первом месте
                </HowItWorksItemTitle>
                <HowItWorksItemDescription>
                  Получить опыт&nbsp;&mdash; это значит много практиковаться.
                  Симулятор с&nbsp;первой главы погружает вас в&nbsp;ситуацию,
                  где вы&nbsp;решаете практические задачи: задаете вопросы,
                  формулируете требования, рисуете диаграммы, пишете код.
                </HowItWorksItemDescription>
              </HowItWorksItemContent>
            </HowItWorksItem>
            <HowItWorksItem>
              <HowItWorksItemArt>
                <img
                  srcSet={`${how_it_works_life_art} 1x, ${how_it_works_life_art2x} 2x`}
                  alt="Изображение планшета и чата"
                />
              </HowItWorksItemArt>
              <MHowItWorksItemArt>
                <img
                  srcSet={`${m_how_it_works_life_art} 1x, ${m_how_it_works_life_art2x} 2x`}
                  alt="Изображение планшета и чата"
                />
              </MHowItWorksItemArt>
              <HowItWorksItemContent>
                <HowItWorksItemTitle>
                  Приближен к&nbsp;жизни
                </HowItWorksItemTitle>
                <HowItWorksItemDescription>
                  Условия работы приближены к&nbsp;реальным. Вы&nbsp;задаете
                  вопросы игровым персонажам, выясняете требования
                  к&nbsp;продукту, предлагаете пути решения. Все проекты
                  вы&nbsp;запускаете в&nbsp;облачной платформе.
                  Мы&nbsp;не&nbsp;расскажем вам, как устроены все известные
                  веб-проекты, но&nbsp;тот опыт, который вы&nbsp;получите
                  в&nbsp;{siteName}, поможет реализовать веб&#8209;сервис любой
                  сложности.
                </HowItWorksItemDescription>
              </HowItWorksItemContent>
            </HowItWorksItem>
            <HowItWorksItem>
              <HowItWorksItemArt>
                <img
                  srcSet={`${how_it_works_multilayers_art} 1x, ${how_it_works_multilayers_art2x} 2x`}
                  alt="Изображение руки в форме Споука из фильма Star Track и другие абстракции"
                />
              </HowItWorksItemArt>
              <MHowItWorksItemArt>
                <img
                  srcSet={`${m_how_it_works_multilayers_art} 1x, ${m_how_it_works_multilayers_art2x} 2x`}
                  alt="Изображение руки в форме Споука из фильма Star Track и другие абстракции"
                />
              </MHowItWorksItemArt>
              <HowItWorksItemContent>
                <HowItWorksItemTitle>
                  Многогранность процесса разработки
                </HowItWorksItemTitle>
                <HowItWorksItemDescription>
                  Принято считать, что разработчик должен писать только код.
                  Развеем миф, это не&nbsp;так. Быть успешным разработчиком
                  значит помогать бизнесу решать задачи. Вы&nbsp;научитесь
                  анализировать требования к&nbsp;продукту, узнаете
                  на&nbsp;практике, какие вопросы полезны, а&nbsp;какие лишь
                  мешают понять проблему.
                </HowItWorksItemDescription>
              </HowItWorksItemContent>
            </HowItWorksItem>
            <HowItWorksItem>
              <HowItWorksItemArt>
                <img
                  srcSet={`${how_it_works_fun_art} 1x, ${how_it_works_fun_art2x} 2x`}
                  alt="Изображение игрового автомата, который оплетают щупальца гигантского осминога"
                />
              </HowItWorksItemArt>
              <MHowItWorksItemArt>
                <img
                  srcSet={`${m_how_it_works_fun_art} 1x, ${m_how_it_works_fun_art2x} 2x`}
                  alt="Изображение игрового автомата, который оплетают щупальца гигантского осминога"
                />
              </MHowItWorksItemArt>
              <HowItWorksItemContent>
                <HowItWorksItemTitle>Фан</HowItWorksItemTitle>
                <HowItWorksItemDescription>
                  В&nbsp;симуляторе есть история и&nbsp;элементы игры.
                  Вы&nbsp;сотрудник службы доставки StarEx. Во&nbsp;время
                  очередной доставки вы&nbsp;терпите кораблекрушение. Селена,
                  ваш голосовой помощник, сажает корабль на&nbsp;неизвестной
                  планете и&nbsp;приводит вас в&nbsp;чувство. Вас впутывают
                  в&nbsp;детективную историю, которая в&nbsp;финале приведет вас
                  домой.
                </HowItWorksItemDescription>
              </HowItWorksItemContent>
            </HowItWorksItem>
          </HowItWorksList>
        </HowItWorksContent>
      </HowItWorksSection>

      <AboutAuthorsSection id="authors">
        <Background>
          <RockerAndPlanetArt>
            <img
              srcSet={`${about_authors_art} 1x, ${about_authors_art2x} 2x`}
              alt="Изображение ракеты и планеты"
            />
          </RockerAndPlanetArt>
          <PinkPlanet2 />
          <SkyBluePlanet2 />
          <SkyPurplePlanet2 />
        </Background>
        <AboutAuthorsContent>
          <AboutAuthorsHeader>
            <AboutAuthorsTitle>Почему мы создали {siteName}</AboutAuthorsTitle>
            <AboutAuthorsDescription>
              <p>
                Недостаток ресурсов по&nbsp;получению практических навыков
                проектирования и&nbsp;масштабирования систем послужил толчком
                к&nbsp;созданию симулятора {siteName}.
              </p>
              <p>
                Симулятор разработан таким образом, чтобы вы&nbsp;смогли
                овладеть нужными навыками за&nbsp;несколько месяцев вместо
                десятка лет.
              </p>
            </AboutAuthorsDescription>
          </AboutAuthorsHeader>
          <AboutAuthorsList>
            <AboutAuthorsItem>
              <MaxItem>
                <AboutAuthorItemHeader>
                  <AboutAuthorItemPhoto>
                    <img
                      srcSet={`${max_koshel_photo} 1x, ${max_koshel_photo2x} 2x`}
                      alt={`Фотография Максима Кошеля, разработчика платформы ${siteName}`}
                    />
                  </AboutAuthorItemPhoto>
                  <AboutAuthorItemHeaderContent>
                    <AboutAuthorItemTitle>Максим Кошель</AboutAuthorItemTitle>
                    <AboutAuthorItemLink
                      href={maxLinkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </AboutAuthorItemLink>
                  </AboutAuthorItemHeaderContent>
                </AboutAuthorItemHeader>
                <AboutAuthorDescription>
                  <p>
                    Несмотря на&nbsp;обилие информации в&nbsp;различных
                    источниках, не&nbsp;существует последовательного изложения
                    концентрированных знаний о&nbsp;проектировании
                    и&nbsp;масштабировании систем для тех, кто сталкивается
                    с&nbsp;этим впервые. Большинство материалов предназначены
                    для опытных разработчиков и&nbsp;направлены на&nbsp;тонкости
                    и&nbsp;оптимизации, но&nbsp;не&nbsp;дают фундаментальных
                    многим недостающих знаний.
                  </p>
                  <p>
                    У&nbsp;меня разносторонний профиль разработки, занимался
                    инфраструктурными задачами, фронтендом и&nbsp;бэкендом.
                    Работал в&nbsp;Яндексе и&nbsp;Рамблере. Разрабатывал проекты
                    Яндекс.Поиск, Яндекс.Карты, Auto.ru, Banki.ru. Участвовал
                    в&nbsp;образовательном проекте&nbsp;&mdash; Школа разработки
                    Яндекс.
                  </p>
                  <p>
                    Пообщавшись с&nbsp;Германом, решили создать нетипичную
                    платформу для получения структурированного практического
                    опыта в&nbsp;проектировании и&nbsp;масштабировании сложных
                    систем. Так родился наш совместный проект&nbsp;&mdash;
                    {siteName}.
                  </p>
                </AboutAuthorDescription>
              </MaxItem>
            </AboutAuthorsItem>
            <AboutAuthorsItem>
              <GermanItem>
                <AboutAuthorItemHeader>
                  <AboutAuthorItemPhoto>
                    <img
                      srcSet={`${german_gurov_photo} 1x, ${german_gurov_photo2x} 2x`}
                      alt={`Фотография Германа Гурова, разработчика платформы ${siteName}`}
                    />
                  </AboutAuthorItemPhoto>
                  <AboutAuthorItemHeaderContent>
                    <AboutAuthorItemTitle>Герман Гуров</AboutAuthorItemTitle>
                    <AboutAuthorItemLink
                      href={germanLinkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </AboutAuthorItemLink>
                  </AboutAuthorItemHeaderContent>
                </AboutAuthorItemHeader>
                <AboutAuthorDescription>
                  <p>
                    Как получить опыт проектирования и&nbsp;разработки
                    веб&#8209;проектов? Топовые IT&nbsp;компании не&nbsp;готовы
                    брать людей без опыта, а&nbsp;в&nbsp;малоизвестных компаниях
                    нечему учиться. Замкнутый круг. Как&nbsp;же получить опыт?
                  </p>
                  <p>
                    Однажды я&nbsp;задумался, а&nbsp;как эту проблему
                    решил&nbsp;я. Прочитал сотни тематических книг, блогов
                    и&nbsp;документаций. Открыл свою компанию по&nbsp;разработке
                    игр. Работал над десятком собственных некоммерческих
                    проектов и&nbsp;много фрилансил. Руководил разработкой
                    в&nbsp;Яндексе и&nbsp;Рамблере. Провел 350 собеседований.
                  </p>
                  <p>
                    Поделился своими мыслями с&nbsp;Максом. Оказалось,
                    я&nbsp;не&nbsp;одинок в&nbsp;своих размышлениях. Результатом
                    наших совместных усилий стал трехмесячный симулятор работы
                    в&nbsp;IT компании. Основная цель симулятора дать именно
                    практические навыки.
                  </p>
                </AboutAuthorDescription>
              </GermanItem>
            </AboutAuthorsItem>
          </AboutAuthorsList>
        </AboutAuthorsContent>
      </AboutAuthorsSection>

      <AlphaTelegramSection>
        <AlphaTelegramContent>
          <AlphaTelegramTitle>
            В&nbsp;апреле запуск альфа-версии.
          </AlphaTelegramTitle>
          <AlphaTelegramTitle>Спешите записаться!</AlphaTelegramTitle>
        </AlphaTelegramContent>
        <AlphaTelegramArtContainer>
          <AlphaTelegramArt>
            <img
              srcSet={`${alpha_telegram_art} 1x, ${alpha_telegram_art2x} 2x`}
              alt="Изображение абстрактных форм. В середине воронка."
            />
          </AlphaTelegramArt>
          <MAlphaTelegramArt>
            <img
              srcSet={`${m_alpha_telegram_art} 1x, ${m_alpha_telegram_art2x} 2x`}
              alt="Изображение абстрактных форм. В середине воронка."
            />
          </MAlphaTelegramArt>
          <AlphaTelegramCTAButtonContainer>
            <AlphaTelegramCTAButton
              href={telegramBot}
              target="_blank"
              rel="noopener noreferrer"
              onClick={CTALinkClick('write_us_telegram_button')}
            >
              <img
                srcSet={`${telegram_logo} 1x, ${telegram_logo2x} 2x`}
                alt="Логотип Телеграма"
              />
              <AlphaTelegramCTAButtonLabel>
                Напиши нам
              </AlphaTelegramCTAButtonLabel>
            </AlphaTelegramCTAButton>
          </AlphaTelegramCTAButtonContainer>
        </AlphaTelegramArtContainer>
      </AlphaTelegramSection>

      <Footer />

      <MMainMenu opened={shouldShowBurgerOverlay}>
        <MSiteName>
          <MLogo>
            <img srcSet={`${logo} 1x, ${logo2x} 2x`} alt="Логотип нердии" />
          </MLogo>
          <span>{siteName}</span>
          <PurpleText>.</PurpleText>
        </MSiteName>
        <MBurgerButton
          onClick={onOpenBurgerOverlay}
          opened={shouldShowBurgerOverlay}
        >
          <img
            srcSet={`${m_burger_icon} 1x, ${m_burger_icon2x} 2x`}
            alt="Кнопка открывает вспомогательное меню по сайту"
          />
        </MBurgerButton>
        <MCloseButton
          onClick={onCloseBurgerOverlay}
          opened={shouldShowBurgerOverlay}
        >
          <img
            srcSet={`${m_close_button} 1x, ${m_close_button2x} 2x`}
            alt="Кнопка закрытия вспомогательное меню по сайту"
          />
        </MCloseButton>
      </MMainMenu>
      <BurgerMenuButton onClick={onOpenBurgerOverlay}>
        <img
          srcSet={`${burger} 1x, ${burger2x} 2x`}
          alt="Кнопка открывает вспомогательное меню по сайту"
        />
      </BurgerMenuButton>
      <BurgerOverlay>
        <BurgerOverlayFader
          opened={shouldShowBurgerOverlay}
          onClick={onCloseBurgerOverlay}
        />
        <BurgerOverlayMenu opened={shouldShowBurgerOverlay}>
          <BurgerOverlayMenuCloseButton onClick={onCloseBurgerOverlay}>
            <img
              srcSet={`${close_button} 1x, ${close_button2x} 2x`}
              alt="Кнопка закрытия вспомогательное меню по сайту"
            />
          </BurgerOverlayMenuCloseButton>
          <BurgerOverlayMenuList>
            <BurgerOverlayMenuItem
              onClick={onMenuItemClick('for-whom')}
              href="#for-whom"
            >
              Кому и&nbsp;зачем
            </BurgerOverlayMenuItem>
            <BurgerOverlayMenuItem
              onClick={onMenuItemClick('skills-you-get')}
              href="#skills-you-get"
            >
              Что вы&nbsp;получите
            </BurgerOverlayMenuItem>
            <BurgerOverlayMenuItem
              onClick={onMenuItemClick('how-it-works')}
              href="#how-it-works"
            >
              Как устроен симулятор
            </BurgerOverlayMenuItem>
            <BurgerOverlayMenuItem
              onClick={onMenuItemClick('authors')}
              href="#authors"
            >
              Авторы
            </BurgerOverlayMenuItem>
          </BurgerOverlayMenuList>
          <HeroSectionCTAButton
            href={telegramBot}
            target="_blank"
            rel="noopener noreferrer"
            onClick={CTALinkClick('overlay_start_button')}
          >
            Начать
          </HeroSectionCTAButton>
          <MBurgerOverlayArt>
            <img
              srcSet={`${laptop} 1x, ${laptop2x} 2x`}
              alt="Изображение абстрактного лаптопа"
            />
          </MBurgerOverlayArt>
        </BurgerOverlayMenu>
      </BurgerOverlay>
    </LandingContiner>
  );
}

export const pageQuery = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        siteName
        socialLinks {
          telegramBot
          maxLinkedin
          germanLinkedin
        }
      }
    }
  }
`;
